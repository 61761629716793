$(function() {

  if($('.js-magnify').length > 0) {


    // Enter & hover
    $('.js-magnify').mousemove(function(event) {
      var $this = $(this);

      // console.log('mouse over');

      var mouseX = event.pageX - $this.offset().left,
          mouseY = event.pageY - $this.offset().top;

      // console.log('mouseX : ' + mouseX);
      // console.log('mouseY : ' + mouseY);

      var offsetX = mouseX - $this.outerWidth() / 2,
          offsetY = mouseY - $this.outerHeight() / 2;

      // console.log('offsetX : ' + offsetX);
      // console.log('offsetY : ' + offsetY);
      // console.log('-----------------------');

      var $movable = $this.find('.c-magnify__movable');

      $movable.css('transform', 'translate3d(' + (offsetX / 10) + 'px, ' + (offsetY / 10) + 'px, 0)');
    });


    // Leave
    $('.js-magnify').mouseleave(function() {
      var $movable = $(this).find('.c-magnify__movable');

      $movable.css('transform', 'translate3d(0, 0, 0)');
    });

  }
});