$(function() {

  var $header = $('.l-header'),
      didScroll = true,
      lastScrollTop = 0, 
      headerTop = 0;

  // Fixed header
  $window.on('scroll', function() {
    didScroll = true;
  });

  (function loop () {
    requestAnimationFrame(loop);
    
    if (didScroll) {
      var scrollTop = $window.scrollTop(),
          scrollDiff = lastScrollTop - scrollTop,
          headerHeight = $header.outerHeight();
          
      if (scrollTop <= 0) {
        // At top (show nav)
        headerTop = 0;
      } else if(scrollDiff > 0) {
        // Scrolling up (show nav)
        headerTop = Math.min(0, headerTop + scrollDiff);
      } else if(scrollDiff < 0 ) {
        // Scrolling down (hide nav)
        headerTop = Math.max(-headerHeight, headerTop + scrollDiff);
      }
      
      if (headerTop <= -headerHeight) {
        $header.addClass('l-header--hidden');
      }
      else {
        $header.removeClass('l-header--hidden');
      }

      if (scrollTop <= headerHeight) {
        $header.addClass('l-header--top');
      }
      else {
        $header.removeClass('l-header--top');
      }
      
      $header.css('top', headerTop);
      
      lastScrollTop = scrollTop;
      
      didScroll = false;
    }
  })();

});